export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "alreadyReviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already Reviewed"])},
        "applicantEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Email"])},
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant ID"])},
        "applicantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Name"])},
        "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants"])},
        "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
        "assignApplicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Applicants"])},
        "backToReviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to list of reviewers"])},
        "completionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion Date"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "failedToAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not assign this applicant. Please try again"])},
        "failedToUnassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not unassign this applicant. Please try again"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
        "searchApplicantLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by applicant name, ID, email"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successfullyAssignedDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You successfully assigned applicant ", _interpolate(_named("applicantName")), " to ", _interpolate(_named("reviewerName")), "."])},
        "successfullyUnassignedDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("applicantName")), " was successfully unassigned from ", _interpolate(_named("reviewerName")), "."])},
        "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassign"])}
      },
      "fr": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "alreadyReviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà évalué"])},
        "applicantEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel du candidat"])},
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du candidat"])},
        "applicantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du candidat"])},
        "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats"])},
        "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer"])},
        "assignApplicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer des candidats"])},
        "backToReviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des examinateurs"])},
        "completionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complété le"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
        "failedToAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas pu atttribuer ce candidat. Veuillez essayer de nouveau."])},
        "failedToUnassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas pu retirer ce candidat. Veuillez essayer de nouveau."])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examinateurs"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
        "searchApplicantLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher par nom, ID, courriel du candidat"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
        "successfullyAssignedDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez bien attribué le candidat ", _interpolate(_named("applicantName")), " à ", _interpolate(_named("reviewerName")), "."])},
        "successfullyUnassignedDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("applicantName")), " a bien été retiré de l'examinateur ", _interpolate(_named("reviewerName")), "."])},
        "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
      }
    }
  })
}
