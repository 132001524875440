<template>
  <header class="container mx-auto">
    <div class="mt-9">
      <a-button
        variant="outline"
        :to="{ name: 'program.reviewers' }"
        data-cy="back-to-reviewers-link"
        @click="
          logEvent({
            eventName: 'SS_NAV_BACK_TO_REVIEWER_LIST',
            userProperties: {
              userID: auth.user?.id
            },
            eventProperties: { reviewerUserId: currentRater?.id }
          })
        "
      >
        <a-arrow class="inline-block w-4 mr-1.5 -mt-1" />
        {{ t('backToReviewers') }}
      </a-button>
    </div>

    <h1
      class="mt-9 font-bold text-3xl text-blue-darkest"
      data-cy="assign-applicants-title"
    >
      {{ t('assignApplicants') }}
    </h1>
    <h2 class="mt-6 text-2xl text-blue-darkest" data-cy="current-rater-name">
      {{ currentRater?.profile?.name }}
    </h2>
    <div class="mt-1 flex items-center">
      <img src="@/assets/icons/email.svg" :alt="t('email')" class="w-5 mr-1" />
      <span data-cy="current-rater-email">{{ currentRater?.emails[0].address }}</span>
    </div>
  </header>

  <main class="container mx-auto">
    <h3 class="mt-16 font-bold text-3xl text-blue-darkest">
      {{ t('applicants') }}
    </h3>
    <div class="w-1/3 mt-4">
      <SearchInput
        v-model="searchString"
        label-sr-only
        :label="t('searchApplicantLabel')"
        data-cy="search-input"
      />
    </div>
    <div class="overflow-x-auto mt-12">
      <a-table
        :columns="[
          { name: 'name', label: t('applicantName') },
          { name: 'email', label: t('applicantEmail') },
          {
            name: 'id',
            label: t('applicantId')
          },
          { name: 'completedAt', label: t('completionDate') },
          { name: 'score' },
          { name: 'reviewers', label: t('reviewers') },
          { name: 'actions', label: t('actions') }
        ]"
        :data="filteredApplicants"
        row-key="id"
        data-cy="assign-applicants-table"
      >
        <template #header-score>
          <div class="text-center">
            {{ t('score') }}
          </div>
        </template>
        <template #cell-name="{ row }">
          <span data-cy="cell-applicant-name">
            {{ row.submission.user.profile?.name }}
          </span>
        </template>
        <template #cell-email="{ row }">
          <span data-cy="cell-applicant-email">
            {{ row.submission.user.emails[0].address }}
          </span>
        </template>
        <template #cell-id="{ row }">
          <span data-cy="cell-applicant-id">
            {{ row.submission.userId }}
          </span>
        </template>
        <template #cell-completedAt="{ row }">
          {{ row.formattedCompletedAt }}
        </template>
        <template #cell-score="{ row }">
          <div class="text-center">
            {{ getRaterScore(row.submission) }}
          </div>
        </template>
        <template #cell-reviewers="{ row }">
          <ul class="flex flex-col">
            <li v-for="rater in row.raters" :key="rater.id" data-cy="cell-reviewers-name">
              {{ rater.profile?.name }}
            </li>
          </ul>
        </template>
        <template #cell-actions="{ row }">
          <div>
            <span v-if="getRaterScore(row.submission) !== null">
              {{ t('alreadyReviewed') }}
            </span>
            <button
              v-else-if="row.raters.includes(currentRater)"
              class="link focus:outline-none"
              @click="unassignRater(row.submission)"
              data-cy="unassign-button"
            >
              {{ t('unassign') }}
            </button>
            <button
              v-else
              class="link focus:outline-none"
              @click="assignRater(row.submission)"
              data-cy="assign-button"
            >
              {{ t('assign') }}
            </button>
          </div>
        </template>
      </a-table>
    </div>
  </main>
</template>

<script lang="ts">
import { ref, computed, defineComponent, watch } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { Submission } from '@/interfaces/interfaces';
import { injectToasts } from '@/components/common/toasts/toasts';
import { authStore } from '@/store/auth';
import { logEvent } from '@/services/amplitude';

import SearchInput from '@/components/common/SearchInput.vue';
import { debounceRef } from '@/services/util';

export default defineComponent({
  name: 'ProgramAssignApplicants',
  components: {
    SearchInput
  },
  props: {
    raterId: { type: String }
  },

  setup(props) {
    const toasts = injectToasts();
    const i18n = useI18n();
    const program = useStore(programStore);
    const auth = useStore(authStore);
    const useMultiReviewers = computed(() => program.useMultiReviewers);

    const currentRater = computed(() =>
      program.raters?.find(r => r.id === props.raterId)
    );

    const submissions = computed(() => program.augmentedSubmissions);

    const reviewerSubmissions = computed(() => {
      if (currentRater.value === undefined) {
        return [];
      }
      if (!useMultiReviewers.value) {
        return submissions.value?.filter(
          s =>
            s.submission.ratings?.length === 0 ||
            (currentRater.value !== undefined && s.raters?.includes(currentRater.value))
        );
      }
      return submissions.value;
    });

    const searchString = debounceRef(ref<string | null>(null), 400);

    watch(searchString, newQuery => {
      if (newQuery !== '') {
        logEvent({
          eventName: 'SS_SEARCHED_APPLICANT_ASSIGN_MODULE',
          userProperties: {
            userID: auth.user?.id
          },
          eventProperties: { searchValue: newQuery ?? undefined }
        });
      }
    });

    const filteredApplicants = computed(() => {
      if (searchString.value === null) {
        return reviewerSubmissions.value;
      }
      return reviewerSubmissions.value?.filter(
        s =>
          searchString.value === null ||
          s.submission.user?.profile?.name
            .toLowerCase()
            .includes(searchString.value.toLowerCase()) ||
          s.submission.user?.emails[0].address
            .toLowerCase()
            .includes(searchString.value.toLowerCase()) ||
          s.submission.userId.toLowerCase().includes(searchString.value.toLowerCase())
      );
    });

    function getRaterScore(submission: Submission) {
      if (currentRater.value === undefined) {
        throw new Error('No current rater');
      }
      const ratersRating = submission.ratings?.find(
        r => r.userId === currentRater.value?.id
      );

      return (ratersRating && ratersRating.score) ?? null;
    }

    async function assignRater(submission: Submission) {
      if (currentRater.value !== undefined && program.currentSchool !== undefined) {
        await program.assignRaters.run([submission.id], [currentRater.value.id]);

        if (program.assignRaters.error !== null) {
          toasts.push({
            title: i18n.t('error'),
            content: i18n.t('failedToAssign'),
            variant: 'danger'
          });
          throw program.assignRaters.error;
        } else {
          logEvent({
            eventName: 'SS_ASSIGNED_REVIEWER',
            userProperties: {
              userID: auth.user?.id
            },
            eventProperties: {
              applicantUserId: submission.userId,
              reviewerUserId: currentRater.value.id
            }
          });
          toasts.push({
            title: i18n.t('success'),
            content: i18n.t('successfullyAssignedDescription', {
              applicantName: submission.user?.profile?.name,
              reviewerName: currentRater.value.profile?.name
            }),
            variant: 'success'
          });
        }
      }
    }

    async function unassignRater(submission: Submission) {
      if (currentRater.value !== undefined) {
        const rating = submission.ratings?.find(r => r.userId === currentRater.value?.id);
        if (rating !== undefined) {
          await program.unassignRater.run(rating.id, submission.id);

          if (program.unassignRater.error !== null) {
            toasts.push({
              title: i18n.t('error'),
              content: i18n.t('failedToUnassign'),
              variant: 'danger'
            });
            throw program.unassignRater.error;
          } else {
            logEvent({
              eventName: 'SS_UNASSIGNED_REVIEWER',
              userProperties: {
                userID: auth.user?.id
              },
              eventProperties: {
                applicantUserId: submission.userId,
                reviewerUserId: currentRater.value.id
              }
            });
            toasts.push({
              title: i18n.t('success'),
              content: i18n.t('successfullyUnassignedDescription', {
                applicantName: submission.user?.profile?.name,
                reviewerName: currentRater.value.profile?.name
              }),
              variant: 'success'
            });
          }
        }
      }
    }

    return {
      t: i18n.t,
      auth,
      logEvent,
      currentRater,
      searchString,
      filteredApplicants,
      assignRater,
      unassignRater,
      getRaterScore
    };
  }
});
</script>

<i18n>
{
  "en": {
    "actions": "Actions",
    "alreadyReviewed": "Already Reviewed",
    "applicantEmail": "Applicant Email",
    "applicantId": "Applicant ID",
    "applicantName": "Applicant Name",
    "applicants": "Applicants",
    "assign": "Assign",
    "assignApplicants": "Assign Applicants",
    "backToReviewers": "Back to list of reviewers",
    "completionDate": "Completion Date",
    "email": "Email",
    "error": "Error",
    "failedToAssign": "We could not assign this applicant. Please try again",
    "failedToUnassign": "We could not unassign this applicant. Please try again",
    "reviewers": "Reviewers",
    "score": "Score",
    "searchApplicantLabel": "Search by applicant name, ID, email",
    "success": "Success",
    "successfullyAssignedDescription": "You successfully assigned applicant {applicantName} to {reviewerName}.",
    "successfullyUnassignedDescription": "{applicantName} was successfully unassigned from {reviewerName}.",
    "unassign": "Unassign"
  },
  "fr": {
    "actions": "Actions",
    "alreadyReviewed": "Déjà évalué",
    "applicantEmail": "Courriel du candidat",
    "applicantId": "ID du candidat",
    "applicantName": "Nom du candidat",
    "applicants": "Candidats",
    "assign": "Attribuer",
    "assignApplicants": "Attribuer des candidats",
    "backToReviewers": "Retour à la liste des examinateurs",
    "completionDate": "Complété le",
    "email": "Courriel",
    "error": "Erreur",
    "failedToAssign": "Nous n'avons pas pu atttribuer ce candidat. Veuillez essayer de nouveau.",
    "failedToUnassign": "Nous n'avons pas pu retirer ce candidat. Veuillez essayer de nouveau.",
    "reviewers": "Examinateurs",
    "score": "Score",
    "searchApplicantLabel": "Chercher par nom, ID, courriel du candidat",
    "success": "Succès",
    "successfullyAssignedDescription": "Vous avez bien attribué le candidat {applicantName} à {reviewerName}.",
    "successfullyUnassignedDescription": "{applicantName} a bien été retiré de l'examinateur {reviewerName}.",
    "unassign": "Supprimer"
  }
}
</i18n>
