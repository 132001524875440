
import { ref, computed, defineComponent, watch } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { Submission } from '@/interfaces/interfaces';
import { injectToasts } from '@/components/common/toasts/toasts';
import { authStore } from '@/store/auth';
import { logEvent } from '@/services/amplitude';

import SearchInput from '@/components/common/SearchInput.vue';
import { debounceRef } from '@/services/util';

export default defineComponent({
  name: 'ProgramAssignApplicants',
  components: {
    SearchInput
  },
  props: {
    raterId: { type: String }
  },

  setup(props) {
    const toasts = injectToasts();
    const i18n = useI18n();
    const program = useStore(programStore);
    const auth = useStore(authStore);
    const useMultiReviewers = computed(() => program.useMultiReviewers);

    const currentRater = computed(() =>
      program.raters?.find(r => r.id === props.raterId)
    );

    const submissions = computed(() => program.augmentedSubmissions);

    const reviewerSubmissions = computed(() => {
      if (currentRater.value === undefined) {
        return [];
      }
      if (!useMultiReviewers.value) {
        return submissions.value?.filter(
          s =>
            s.submission.ratings?.length === 0 ||
            (currentRater.value !== undefined && s.raters?.includes(currentRater.value))
        );
      }
      return submissions.value;
    });

    const searchString = debounceRef(ref<string | null>(null), 400);

    watch(searchString, newQuery => {
      if (newQuery !== '') {
        logEvent({
          eventName: 'SS_SEARCHED_APPLICANT_ASSIGN_MODULE',
          userProperties: {
            userID: auth.user?.id
          },
          eventProperties: { searchValue: newQuery ?? undefined }
        });
      }
    });

    const filteredApplicants = computed(() => {
      if (searchString.value === null) {
        return reviewerSubmissions.value;
      }
      return reviewerSubmissions.value?.filter(
        s =>
          searchString.value === null ||
          s.submission.user?.profile?.name
            .toLowerCase()
            .includes(searchString.value.toLowerCase()) ||
          s.submission.user?.emails[0].address
            .toLowerCase()
            .includes(searchString.value.toLowerCase()) ||
          s.submission.userId.toLowerCase().includes(searchString.value.toLowerCase())
      );
    });

    function getRaterScore(submission: Submission) {
      if (currentRater.value === undefined) {
        throw new Error('No current rater');
      }
      const ratersRating = submission.ratings?.find(
        r => r.userId === currentRater.value?.id
      );

      return (ratersRating && ratersRating.score) ?? null;
    }

    async function assignRater(submission: Submission) {
      if (currentRater.value !== undefined && program.currentSchool !== undefined) {
        await program.assignRaters.run([submission.id], [currentRater.value.id]);

        if (program.assignRaters.error !== null) {
          toasts.push({
            title: i18n.t('error'),
            content: i18n.t('failedToAssign'),
            variant: 'danger'
          });
          throw program.assignRaters.error;
        } else {
          logEvent({
            eventName: 'SS_ASSIGNED_REVIEWER',
            userProperties: {
              userID: auth.user?.id
            },
            eventProperties: {
              applicantUserId: submission.userId,
              reviewerUserId: currentRater.value.id
            }
          });
          toasts.push({
            title: i18n.t('success'),
            content: i18n.t('successfullyAssignedDescription', {
              applicantName: submission.user?.profile?.name,
              reviewerName: currentRater.value.profile?.name
            }),
            variant: 'success'
          });
        }
      }
    }

    async function unassignRater(submission: Submission) {
      if (currentRater.value !== undefined) {
        const rating = submission.ratings?.find(r => r.userId === currentRater.value?.id);
        if (rating !== undefined) {
          await program.unassignRater.run(rating.id, submission.id);

          if (program.unassignRater.error !== null) {
            toasts.push({
              title: i18n.t('error'),
              content: i18n.t('failedToUnassign'),
              variant: 'danger'
            });
            throw program.unassignRater.error;
          } else {
            logEvent({
              eventName: 'SS_UNASSIGNED_REVIEWER',
              userProperties: {
                userID: auth.user?.id
              },
              eventProperties: {
                applicantUserId: submission.userId,
                reviewerUserId: currentRater.value.id
              }
            });
            toasts.push({
              title: i18n.t('success'),
              content: i18n.t('successfullyUnassignedDescription', {
                applicantName: submission.user?.profile?.name,
                reviewerName: currentRater.value.profile?.name
              }),
              variant: 'success'
            });
          }
        }
      }
    }

    return {
      t: i18n.t,
      auth,
      logEvent,
      currentRater,
      searchString,
      filteredApplicants,
      assignRater,
      unassignRater,
      getRaterScore
    };
  }
});
